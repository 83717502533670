.inputBox{
    margin-bottom: 10px;
    margin-top: 10px;
}
.submit-body h3{
    margin-bottom: 5px;
}

.bordered {
    border: 2px solid #005b82;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #f1f5f9;
  }