.navbar {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: 1000;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc; 
  }
  
  .navbar h1 {
    font-size: 2.5rem; 
    font-weight: bold; 
    color: #005B82; 
    margin: 0;
    margin-left: 30px; 
    transition: ease-in-out 0.2s;
  }

.log-in-button {
    margin-right: 30px;
}

.logged-in{
  background-color: white;
  color:#005B82;
  border: 1px solid #005B82;
}

.status{
  border-radius: 99999px;
  border: none;
  width: 25px;
  height: 25px;
}
.logo:hover{
  cursor:pointer;
  transform: scale(110%);

}
.user-display{
  display: flex;
  gap: 2rem;
  align-items: center;
}
.user-display>p{
  margin: 0;
}