.filter-content-container {
    display: flex;
  }
  
.search-container {
    margin-right: 20px;
    position: relative;
}
.suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    z-index: 1;
}

 .datepicker-container {
    --a-spacing-2: 0; /* Super irritating grid spacing that caused the datePicker components to not be properly vertically aligned */
} 

.navds-date__field-wrapper input{
    padding-left:10px; /* Also when I manually overried the grid spacing-2 above, the padding would disappear and the text
    went all the way to the left. Very annoying */
}

.icon-container {
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
    margin-right: 5px;
    margin-left: 5px;
}

.chips-container{
    display:flex;
    margin-top:15px;
}

.checkboxesAndButton{
    display:flex;
    margin-top:15px;
}

.checkboxParent{
    display:flex;
    flex-direction: row;
    margin-right: 40px;
}

.top-row{
    display: flex;
}
.top-row .navds-checkbox:first-child {
    margin-right: 20px;
}

.Buttons{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin-left: 25px;
}