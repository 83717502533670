.landing-container {
    text-align: center;
    padding-top: 10px;
    /* Maybe nice with the line not going all the way?
    padding-left: 5px;
    padding-right: 5px;
    */
    font-family: 'Source Sans Pro', sans-serif; 
}

.log-in-button {
    margin-right: 30px;
}

.content {
    max-width: 650px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.search-container {
    display: flex;
    align-items: center; /* Center the items vertically */
    width: 100%;
}
.search-bar{
    margin-right: 10px;
}
.content h2 {
    margin: 0;
    text-align: center;
    font-size: 2.5rem; 
    color: #323A45; 
    margin-bottom: 24px;
    
}

.img {
    margin-top: 50px;
}

.filter-icon{
    cursor: pointer;
}

.rotated {
    transform: rotate(180deg);
    transition: transform 0.5s ease; /* Smooth transition for rotation */
  }

.popover-container{
    max-width: 500px;
}
.content-headers{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 70px;
}

.vju-logo {
    font-size: 10rem;
    width: fit-content;
    font-weight: bold;
    color: #005B82;
    margin: 0;
    margin-top:130px;
    transition: ease-in-out 0.3s;
}
.vju-logo:hover{
    transform: scale(120%);
    cursor: pointer;
}