.error-display-container{
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
}
.error-code{
    font: 'Source Sans 3', sans-serif;
    font-size: 150px;
    font-weight: 700;
    color: var(--a-deepblue-700);
    margin-top: 40px;
    margin-bottom: -10px;
}

.header{
    font: 'Source Sans 3', sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: var(--a-deepblue-700);
    margin-top: 0px;
    margin-bottom: 10px;
}

.error-message{
    font: 'Source Sans 3', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: var(--a-deepblue-700);
    margin-bottom: 40px;
    max-width: 700px;
}

.pic{
    width:30%;
    height:30%;
}