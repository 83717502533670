.searchResultsShelf{
    display: flex;
    flex-direction: column;
}

.searchResultsShelf h1{
    margin-top: 10px;
    margin-bottom: 10px;
}

.searchResultsShelf h2{
    margin-bottom: 10px;
}
.searchResultsLeft{
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    background-color: white;
}
.searchResultsRight{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 90vh;
}
.searchResultsWrapper{
    display: flex;
    height: calc(100vh - 75px);
    background-color: #CCE1FF;
}
.selectedRowOutline {
    border: 3px solid #0067c5 !important;
    box-sizing: border-box !important;
}
.tableRow:hover{
    cursor: pointer;
}
.filterList{
    width: 50%;
}

.documents-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.row-buttons{
    display: flex;
    gap: 3rem;
    align-items: center;
}
.avsendermottaker-info{
    display: flex;
    gap: 2rem;
}
.avsendermottaker-info > p{
    margin: 0;
}
.jp-info-section{
    border-left: 2px solid gray;
    padding: 1rem 0rem;
    padding-left: 2rem;
    border-radius: 6px;

}
.jp-title{
    margin: 0;
    margin-bottom: 1rem;
}
.jp-content{
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.jp-divider{
    height: 1px;
    width: 100%;
    background-color: rgb(179, 179, 179);
}
.search-result-loading{
    display: flex;
    width: 100%;
    height: calc(100vh - 85px);
    background-color: white;
    justify-content: center;
}